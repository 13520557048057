// Using as a global stlying for mobile

@include media-breakpoint-down(sm) {
    h1 {
        font-size: 2.875rem;
        line-height: 3.625rem;
    }

    h2 {
        font-size: 2.25rem;
        line-height: 2.875rem;
    }

    #content-new {
        padding-top: 0;
        top: 3.75rem;

        .container {
            max-width: 100%;
        }
    }
}
