// The fonts are placed in a root fonts folder so they are easier to find for the build
$font-path: '/fonts/';

/* museo sans rounded - normal*/
@font-face {
    font-family: 'Museo Sans Rounded';
    font-style: normal;
    font-display: swap;
    font-weight: 100;
    src:
        local('Museo Sans Rounded'),
        url($font-path + 'museo-sans-rounded/MuseoSansRounded-100.otf') format('opentype');
}

/* museo sans rounded - italic*/
@font-face {
    font-family: 'Museo Sans Rounded';
    font-style: italic;
    font-display: swap;
    font-weight: 100;
    src:
        local('Museo Sans Rounded'),
        url($font-path + 'museo-sans-rounded/MuseoSansRounded-100_Italic_22.otf') format('opentype');
}

/* museo sans rounded - normal*/
@font-face {
    font-family: 'Museo Sans Rounded';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src:
        local('Museo Sans Rounded'),
        url($font-path + 'museo-sans-rounded/MuseoSansRounded-300.otf') format('opentype');
}

/* museo sans rounded - italic*/
@font-face {
    font-family: 'Museo Sans Rounded';
    font-style: italic;
    font-display: swap;
    font-weight: 300;
    src:
        local('Museo Sans Rounded'),
        url($font-path + 'museo-sans-rounded/MuseoSansRounded-300_Italic_22.otf') format('opentype');
}

/* museo sans rounded - semibold*/
@font-face {
    font-family: 'Museo Sans Rounded';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src:
        local('Museo Sans Rounded'),
        url($font-path + 'museo-sans-rounded/MuseoSansRounded-500.otf') format('opentype');
}

/* museo sans rounded - semibold italic*/
@font-face {
    font-family: 'Museo Sans Rounded';
    font-style: italic;
    font-display: swap;
    font-weight: 500;
    src:
        local('Museo Sans Rounded'),
        url($font-path + 'museo-sans-rounded/MuseoSansRounded-500_Italic_22.otf') format('opentype');
}

/* museo sans rounded - bold*/
@font-face {
    font-family: 'Museo Sans Rounded';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src:
        local('Museo Sans Rounded'),
        url($font-path + 'museo-sans-rounded/MuseoSansRounded-700.otf') format('opentype');
}

/* museo sans rounded - bold-italic*/
@font-face {
    font-family: 'Museo Sans Rounded';
    font-style: italic;
    font-display: swap;
    font-weight: 700;
    src:
        local('Museo Sans Rounded'),
        url($font-path + 'museo-sans-rounded/MuseoSansRounded-700_Italic_22.otf') format('opentype');
}
