/******************************************************************************/
/* BOOTSTRAP-OVERRIDES
/*     These use basic css, but refer to our _variables.scss partial when
/*     necessary.
/*     TODO: Break all these out into partials so components can include when necessary
/*     vs polluting the global scope when not needed.
/***********************************/

// Body
body {
    background-color: $white;
    color: $black;
}

button.btn-sm {
    padding: 0.625rem 1rem;
    line-height: 0.875rem;
    font-size: 0.875rem;
    height: 2.1875rem;
    font-weight: 300;
}

// Nav
// vertical nav
.nav.flex-column {
    list-style: none;
    padding-left: 0;
    margin: 0;
    flex-wrap: nowrap;
    li.nav-item {
        a {
            padding: 0.75rem;
            display: block;
            position: relative;
            text-decoration: none;
            i {
                margin-right: 0.625rem;
            }
        }
        a:hover,
        &.active,
        &.active a:hover {
            background-color: rgba($ae-gray-2, 0.45);
        }
        .navbar-dark & {
            a:hover,
            &.active,
            &.active a:hover {
                background-color: rgba($ae-gray-2, 0.1);
            }
        }
    }
}
// If the navbar is dark (like admin routes)
.navbar-dark {
    .nav-item {
        a.nav-link {
            color: $navbar-dark-color;
        }
        a.nav-link:hover {
            color: $navbar-dark-hover-color;
        }
    }
    .nav-item.active {
        a.nav-link {
            color: $navbar-dark-active-color;
        }
    }
}
// Nav Links
.nav-links {
    display: flex;
    flex-basis: auto;
    flex-grow: 1;
    align-items: center;
    .nav-link-group {
        display: flex;
    }
}
// Nav Tabs
.nav-tabs {
    .nav-link {
        &.disabled {
            opacity: 0.5;
        }
    }
}

// Nav Primary styles (could also use :host.is-primary-nav to target)
#nav-primary {
    .nav-links {
        .nav-link-group {
            flex-direction: row;
        }
        // Hide most links when at or below sm size
        @include media-breakpoint-down(sm) {
            .user-links,
            .admin-links {
                display: none;
            }
        }
        .nav-item {
            box-shadow: inset 0 0 0 $ae-green;
            transition: 0.15s ease-out;
            a.nav-link {
                padding: 0.6875rem;
                i {
                    display: none;
                }
            }
            &:hover,
            &.active {
                box-shadow: inset 0 -4px 0 $ae-green;
                font-weight: $font-weight-normal;
            }
        }
    }
}
// Nav styles (could also use :host.is-nav to target)
#nav {
    .nav-item {
        box-shadow: inset 0 0 0 $ae-green;
        transition: 0.15s ease-out;
        &:hover,
        &.active {
            box-shadow: inset 4px 0 0 $ae-green;
        }
    }
}
// Nav Right styles (could also use :host.is-right-nav to target)
#nav-right {
    .nav-links {
        display: block;
        .nav-link-group {
            width: 100%;
            flex-direction: column;
        }
        .nav-item {
            a.nav-link {
                color: $ae-gray-1;
                padding: 0.625rem 0.78125rem 0.625rem 0.78125rem;
                text-decoration: none;
                i {
                    margin-right: 0.625rem;
                }
            }

            a.nav-link.active,
            a.nav-link.active:hover {
                font-weight: $font-weight-normal;
                color: $ae-green;
                background-color: $ae-gray-3;
            }
            a.nav-link:hover {
                color: $white;
                background-color: rgba($ae-gray-3, 0.45);
            }
        }
        .nav-item.active {
            font-weight: $font-weight-normal;
        }
    }
}

// This overwrites the current card-deck class so that we can more granularly
// control the amount of cards in the row
.card-deck {
    margin-top: 10px;
    column-gap: 1.25rem;
    row-gap: 1.25rem;
    grid-template-columns: repeat(5, auto);

    ::ng-deep bb-content-card .card {
        // set the max-width and height: 100% on your specific card component to control the columns
        margin-left: 0;
        margin-right: 0;
    }
}

// Cards
.card {
    .card-header.is-clickable,
    .card-header.has-button {
        color: $ae-gray-3;
        font-weight: $font-weight-normal;
        padding: 0;
        .row {
            margin-left: 0;
            margin-right: 0;
        }
        .btn {
            padding: 0.4375rem;
            color: $ae-gray-3;
        }
        .btn:hover {
            background-color: $ae-gray-3;
        }
        div.header-text {
            padding: 0.46875rem 0.78125rem 0.46875rem 0.78125rem;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .card-header.is-clickable {
        div.header-text:hover {
            background-color: $ae-gray-2;
        }
        div.header-text.text-success {
            .fa-circle {
                margin-right: 0.3125rem;
            }
        }
    }
    .card-link:hover {
        text-decoration: underline;
    }
    .card-footer {
        padding: 0.25rem;
        background-color: $white;
        text-align: right;
    }
    .card-footer.is-clickable:hover {
        background-color: rgba($ae-background-1, 0.2);
    }
}

///////////////////////////////////////////////////
//              Modal                            //
///////////////////////////////////////////////////

.modal-title {
    font-weight: $font-weight-bold;
}
.modal-content {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.26);
}
.modal-body {
    max-height: calc(100vh - 7.8125rem);
    overflow: auto;
}

.modal {
    text-align: center;
    padding: 0 !important;
}

.modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px; /* Adjusts for spacing */
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.modal-component {
    .modal-dialog {
        width: 36%;

        .modal-content {
            background-color: white;

            .modal-body {
                padding-left: 2.8125rem;
                padding-right: 2.8125rem;
            }

            .modal-footer {
                margin-bottom: 1.875rem;
                border-top: none;
                justify-content: center;

                #cancel-btn {
                    margin-right: 0.625rem;
                }

                &.z-depth-1-top {
                    box-shadow: none;
                }
            }

            .modal-header {
                text-align: center !important;
                padding: 0.625rem 0.9375rem;

                .modal-title {
                    width: 100%;
                    font-weight: 300;
                    margin-top: 1.875rem;
                }

                .close {
                    font-size: 1.25rem;
                }
            }
        }
    }
}

// UPDATES FOR MODALS IN NUCLEUS ORGS
.modal-component .modal-dialog.org-modal {
    font-size: 16px;
    max-width: 500px;
    width: inherit;
    margin: 0;
    padding: 10px;

    &.large {
        max-width: 800px;
    }

    .modal-header {
        border-bottom: none;
        margin: 0;
        background: #eee;
        display: flex;
        align-items: center;

        h1 {
            font-size: 2em;
        }

        .modal-title {
            margin: 0;
            text-align: left;
        }

        .close {
            font-size: 2.5em;
        }
    }

    .helper-text {
        display: none;
    }

    .modal-body {
        padding: 10px;

        .form-row {
            margin: 0;
            padding: 10px;
            font-size: 1em;
        }
    }

    .modal-footer {
        margin-bottom: 1.25rem;
    }
}

// Tables
.table {
    margin-bottom: 0;
    thead th {
        border-top: 0;
        border-bottom: 1px;
        font-weight: $font-weight-normal;
        white-space: nowrap;
    }
    th,
    td {
        vertical-align: middle;
    }
    tr.is-clickable:hover {
        background-color: $ae-gray-1;
    }
}
// custom anaconda styled table
.table.table-anaconda {
    tbody tr {
        cursor: pointer;
    }
    tbody tr:hover {
        background-color: $white;
    }
    td {
        color: $gray-500;
    }
    tr td:first-child {
        color: $primary;
        font-weight: $font-weight-bold;
    }
}
// sortable table
.table.sortable {
    thead th {
        cursor: pointer;
        user-select: none;
    }
    thead th i.fal {
        visibility: hidden;
        margin-left: 0.15625rem;
    }
    thead th.active i.fal {
        visibility: visible;
    }
}
// borderless
.table.table-borderless td,
.table.table-borderless th {
    border-top: none;
    vertical-align: top;
}

// Faux table
//     Made with .col and divs. Use when you need collapsible rows (which can't be
//     done with a table). E.g. Runs list used to have it, but AEUI-695 removed it
.faux-table {
    .faux-table-thead {
        font-weight: $font-weight-bold;
    }
    .row {
        margin-left: 0;
        margin-right: 0;
        border-bottom: 1px solid $ae-gray-3;
        .col {
            padding: 0.1875rem;
        }
    }
    .row-expanded {
        padding-left: 1.25rem;
        background-color: $ae-gray-1;
        pre {
            max-height: 6.25rem;
        }
    }
}
.faux-table.faux-table-hover {
    .row:not(.row-expanded):hover {
        background-color: $ae-gray-1;
    }
}

// Breadcrumb
.breadcrumb {
    padding-left: 0;
    background: none;
    font-weight: $font-weight-base;
    .breadcrumb-item[routerLink],
    .breadcrumb-item[ng-reflect-router-link] {
        cursor: pointer;
    }
    .breadcrumb-item[routerLink]:hover,
    .breadcrumb-item[ng-reflect-router-link]:hover {
        text-decoration: underline;
    }
    .breadcrumb-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        outline: none;
    }
}

// Code and Pre
code,
samp,
kbd {
    font-family: 'Courier New', Courier, monospace, sans-serif;
    text-align: left;
    color: $ae-gray-4;
}
pre code {
    line-height: 1rem;
    font-size: 0.53125rem;
}
pre {
    padding: 0.1875rem 0.3125rem 0.1875rem 0.4375rem;
    border: 1px solid $ae-gray-3;
    border-radius: 3px;
    background-color: $ae-gray-2;
    margin: 0.15625rem;
    overflow: auto;
    width: 99%;
}
pre.log-text {
    font-size: 0.75em;
    background-color: $black;
    color: $ae-gray-4;
}

/* target IE7 and IE6 */
*:first-child + html pre {
    padding-bottom: 1.25rem;
    overflow-y: hidden;
    overflow: visible;
    overflow-x: auto;
}
* html pre {
    padding-bottom: 1.25rem;
    overflow: visible;
    overflow-x: auto;
}

// FORMS
// inputs
.form-control,
.form-control {
    font-weight: $font-weight-base;
    font-size: 1rem;
    height: 2.25rem;
}
.form-control:disabled:focus,
.form-control[readonly]:focus {
    border-color: $input-border-color;
    box-shadow: none;
}

// Input Group
.input-group {
    button {
        height: 2.25rem;
    }
}

// Hide the terrible default arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

// ng-dropdown
.ng-dropdown-panel {
    .ng-dropdown-panel-items {
        .ng-option.selected {
            background-color: $ae-gray-2;
        }
        .ng-option.marked {
            background-color: $ae-gray-2;
        }
    }
}

// small
small,
.small {
    font-weight: $font-weight-base;
}
// buttons
// More info on disabled attr. vs class - https://getbootstrap.com/docs/4.1/components/buttons/#disabled-state

.btn {
    border-radius: 1.5625rem;
    font-size: 1rem;
    padding: 0.625rem 1.5rem;
    font-weight: 500;

    &[disabled],
    &:disabled,
    &.disabled {
        background-color: $ae-gray-1 !important;
        border-color: $ae-gray-1 !important;
        color: $ae-gray-4;
    }
    &.btn-primary {
        background-color: $ae-primary-8;
        border-color: $ae-primary-8;
    }
    &.btn-outline-info {
        border: 2px solid $ae-accent-3;
    }
    &.btn-primary:not(.disabled):hover {
        background-color: $ae-secondary-4;
        border-color: $ae-secondary-4;
    }
    &.btn-tertiary {
        outline: 0 none;
        color: $ae-accent-4;
        align-self: center;
        text-transform: uppercase;
        font-size: 1rem;
        padding: 0;
        &:hover {
            align-self: center;
        }
        &[disabled],
        &.disabled {
            // Bootstrap uses the !important flag when setting disabled button background color
            background-color: transparent !important;
            border: 0 none;
        }
    }
    &.btn-light {
        border-width: 1px;
        border-color: $ae-secondary-2;
        background-color: white;
        color: $ae-secondary-2;
    }
    &.btn-outline-light {
        border-width: 1px;
        border-color: $ae-gray-3;
        color: $black;
    }
    &.btn-danger {
        border-width: 1px;
        border-color: $danger;
    }
    &.btn-danger:hover {
        background-color: #960824;
    }
    &.btn-icon:hover {
        background-color: $ae-gray-2;
    }
    & .dropdown-item {
        font-weight: $font-weight-base;
    }
}

// Not really an override but re-addition of xs class
.btn-group-xs > .btn,
.btn-xs {
    padding: 0.15625rem 0.25rem;
    font-size: 0.546875rem;
    line-height: 0.5;
    border-radius: 0.125rem;
}

// Hacky fix for no space between .btn that we're suddenly seeing
// after Angular 7 upgrade.
.page {
    button.btn + button.btn {
        margin-left: 0.3125rem;
    }
}

// Dropdown
.dropdown-menu {
    cursor: pointer;
    .dropdown-header {
        cursor: default;
        padding: 0.3125rem 0.46875rem;
        color: $ae-gray-3;
        font-size: 1rem;
    }
    .dropdown-item {
        font-weight: $font-weight-base;
        font-size: 1rem;
    }
    .dropdown-item.active,
    a.dropdown-item:active,
    a.dropdown-item:hover {
        color: $black;
        background-color: $ae-gray-2;
    }
    .dropdown-item.delete {
        color: $ae-accent-2;
    }
    a.dropdown-item.delete:active,
    a.dropdown-item.delete:hover {
        color: $white;
        background-color: $ae-accent-2;
    }
}

// Badges
.badge {
    font-size: 1rem;
    font-weight: $font-weight-normal;
}
.badge-fw {
    vertical-align: text-bottom !important;
    max-width: 3.75rem;
    width: 3.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.badge-circle {
    border-radius: 50%;
    height: 0.9375rem;
    width: 0.9375rem;
    padding: 0.25rem 0.15625rem;
}
.badge-circle {
    border-radius: 50%;
    height: 0.9375rem;
    width: 0.9375rem;
    padding: 0.25rem 0.15625rem;
}

// Notification Badge (put the .badge-notification on the parent DOM)
.badge-notification-wrapper {
    position: relative;
    .badge-notification {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 65%;
    }
}

// File Upload
.custom-file {
    .custom-file-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 3.125rem;
    }
}

// Form Validation
textarea.ng-dirty.ng-invalid,
.form-control.invalid,
.form-control.is-invalid {
    border-color: $danger;
    background-image: none;
    // Sibling selector to turn the whole group red when invalid
    & + .input-group-append .input-group-text {
        border: 1px solid $danger;
        border-left: 0 none;
    }
}

// Backgrounds
.bg-success-light,
.form-control.bg-success-light {
    background-color: rgba($ae-background-1, 0.4);
}

$spacer: 0.625rem !default;
$spacers: () !default;
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.5,
        ),
        5: (
            $spacer * 3,
        ),
        6: (
            $spacer * 8,
        ),
    ),
    $spacers
);
