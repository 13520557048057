// This can be the start of very specific styles for big bend outside of the
// `theme_02` that is being used now in the shared library.
@import './theme_02/main';
@import './kendo-theme/overrides';

body.app-body {
    min-width: 350px;
    font-family: 'Inter', sans-serif;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Inter', sans-serif;
        margin: 0;
    }
}

// TODO_REMOVE when the design system covers expandable panels.
#subscription-modification-page {
    .k-expander-title {
        text-transform: none !important;
        color: var(--kendo-color-on-app-surface, #2c2c2c) !important;
        font-size: var(--kendo-font-size-sm) !important;
        font-weight: 600 !important;
    }
    .k-expander-content {
        padding: 0 15px 10px 15px !important;
    }
}

// The design system sets these values to Museo.
// Overriding them here for .cloud only to avoid having unexpected side
// effects for other apps, but we should clean that up and remove this.

.cdk-overlay-container {
    position: fixed;
    z-index: 1000;
}

.cdk-overlay-connected-position-bounding-box {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px;
}

.cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    max-height: 100%;
}

hr.gray {
    width: 100%;
    height: 1px;
    background: #d3d4d2;
}

// Green info section currently used on the pricing and checkout pages.
// Replace if/when the design system has an element for this.
.info-notice {
    background-color: #e2f9e9;
    color: #077c2d;
    padding: 1rem;
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    display: flex;
    justify-content: start;
    font-size: 15px !important;
    a {
        color: #077c2d !important;
        font-size: 15px !important;
        padding: 0 auto;
        &:hover {
            color: #0c4b20 !important;
        }
    }
    div {
        padding: 7px;
    }
    div.fa-circle-info {
        font-size: 20px;
    }
}

.stripe-trust-badge {
    width: 6.69rem; // ~107px
    height: 1.75rem; // 28px
}
