/******************************************************************************/
/* 3RDPARTY-OVERRIDES
/*     Overrides for 3rd party libs.
/***********************************/

.overlay-container {
    position: relative;
    z-index: 1000;
}

.ng-dropdown-panel {
    font-size: 1rem;
}

// ngx-bootstrap Datepicker
// https://valor-software.com/ngx-bootstrap/#/datepicker#themes
.theme-green .bs-datepicker-head {
    background-color: $primary;
}
