/******************************************************************************/
/* GLOBALS
/*     These are styles that will be available on every page to any component.
/***********************************/

// Imports
@import './variables';

// Defaults
a,
.is-clickable {
    cursor: pointer;
}
.noscroll {
    overflow-y: hidden;
}
.noselect {
    user-select: none;
}
.nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.nowrap-left {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    text-align: left;
}
// Display flex with text-overflow ellipsis
// Inspiration from: https://css-tricks.com/flexbox-truncated-text/
// .d-flex .flex-child-ellipsis {
//     min-width: 0;
//     max-width: 50%;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }

:disabled,
.noclick {
    pointer-events: none;
    cursor: not-allowed;
}
.force-word-break {
    display: block;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    hyphens: auto;
}
td.force-word-break {
    display: table-cell;
}

// Taken from: https://css-tricks.com/centering-css-complete-guide/
.center-both-directions-flex {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.center-both-directions-absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    text-align: center;
}

// Right Nav
#nav-primary,
#main {
    /* Force Hardware Acceleration */
    transform: translateZ(0);
    // ONLY transition the transform (this way #main doesn't animate when you simply resize the browser)
    transition: transform;
    transition-duration: $nav-speed;
}
// Right Nav is open
body.rightnav-is-open {
    #main,
    #nav-primary {
        transform: translate3d(-$ae-nav-right-width, 0, 0);
    }
    #nav-right {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}
// Should we remove the docked stuff? We don't really use it and it makes no sense with height:100%;
// Right Nav is docked
// body.rightnav-is-docked {
//     #nav-right {
//         z-index: 1029;
//     }
//     #nav-secondary {
//         right:$ae-nav-right-width;
//     }
//     #main {
//         margin-right:$ae-nav-right-width;
//         #content {
//             right:$ae-nav-right-width;
//             width:calc(100% - #{$ae-nav-right-width});
//         }
//     }
//
// }
//

// SUBNAV
.nav.subNav {
    padding-left: 2em;
}

#content {
    position: absolute; // IE11 doesn't like position: fixed + translate3d
    right: 0;

    top: #{$ae-nav-primary-height}; // Must interpolate vars inside calc() with #{};

    bottom: 0;
    overflow: auto;
    background-color: $white;
    transition: all $nav-speed ease;
    //padding: 0.625rem 0.625rem 0.625rem 0.625rem;

    &.public {
        background-color: $ae-gray-2;
        top: $ae-nav-secondary-height;
    }
}

body.nav-is-open.nav-is-docked #content {
    @include media-breakpoint-up(lg) {
        width: calc(100% - #{$ae-nav-full-size});
    }

    @include media-breakpoint-down(md) {
        width: calc(100% - #{$ae-nav-half-size});
    }
}

#content-new {
    position: absolute; // IE11 doesn't like position: fixed + translate3d
    right: 0;
    left: 0;
    top: $ae-nav-primary-height;
    overflow: auto;
    background-color: $white;
    padding-top: 0.625rem;
    bottom: 0;
}

#content-child {
    position: absolute; // IE11 doesn't like position: fixed + translate3d
    right: 0;
    top: calc(#{$ae-nav-secondary-height}); // Must interpolate vars inside calc() with #{};
    bottom: 0;
    overflow: auto;
    background-color: $white;
    padding: 0.625rem 0.625rem 0.625rem 0.625rem;

    &.public {
        background-color: $ae-gray-2;
        top: $ae-nav-secondary-height;
    }
}
// Nested pages like Deployments in Projects
#content #content-inner #content-child {
    // top: 0;
    // background-color: $ae-gray-2;
}

// Override the #content width for sm and lower
// (see nav-tertiary for corresponding nav styles)
@include media-breakpoint-down(sm) {
    #content.col-11 {
        flex: 0 0 calc(100% - #{$ae-nav-primary-height});
        max-width: calc(100% - #{$ae-nav-primary-height});
    }
}
#content-inner {
    min-height: 100%;
    background-color: $white;
    padding: 0;
}
#content-inner-child {
    min-height: 100%;
    background-color: $white;
    padding: 0.625rem;
    overflow-x: auto;
}

// No Content
// If no content on top level #content
#content.nocontent {
    background-color: $ae-gray-2;
    #content-inner {
        background-color: $ae-gray-2;
    }
}
// If no content is on a child of #content
#content #content-inner .nocontent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $ae-gray-2;
}
.nocontent-message {
    color: $ae-green;
}

// Page
.page {
    position: relative;
    height: 100%;
    padding: calc(#{$ae-nav-primary-height} + 0.625rem) 0.625rem 0rem 0.625rem; // Must interpolate vars inside calc() with #{}
}
.page.with-nav-secondary {
    padding-top: calc(
        #{$ae-nav-primary-height} + #{$ae-nav-secondary-height} + 0.625rem
    ); // Must interpolate vars inside calc() with #{}
}
.page.with-nav-tertiary {
    #content {
        background-color: $ae-gray-2;
    }
}

// iFrame
.iframe-wrapper {
    position: absolute;
    .iframe-content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
.iframe-wrapper-full {
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.iframe-wrapper-inset {
    top: 3.75rem;
    bottom: 0.625rem;
    left: 0.625rem;
    right: 0.625rem;

    &.is-child {
        // stupid ass hack. sorry
        top: 0.625rem;
    }
}

// Line Clamps
.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.line-clamp-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    overflow: hidden;
}
td.line-clamp-2 {
    height: 2.625rem;
}
td.line-clamp-3 {
    height: 3.5625rem;
}
td.line-clamp-4 {
    height: 4.5rem;
}

// Z-Depth (based off Material Design)
.z-depth-half {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}
/* from material shadows https://github.com/mrmlnc/material-shadows */
.z-depth-1 {
    box-shadow:
        0 2px 5px rgba(0, 0, 0, 0.16),
        0 2px 10px rgba(0, 0, 0, 0.12);
}
.z-depth-1-top {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
}
.z-depth-1-bottom {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
}
.z-depth-2 {
    box-shadow:
        0 8px 17px rgba(0, 0, 0, 0.2),
        0 6px 20px rgba(0, 0, 0, 0.19);
}
.z-depth-2-top {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.19);
}
.z-depth-2-bottom {
    box-shadow: 0 8px 17px rgba(0, 0, 0, 0.2);
}
.z-depth-3 {
    box-shadow:
        0 12px 15px rgba(0, 0, 0, 0.24),
        0 17px 50px rgba(0, 0, 0, 0.19);
}
.z-depth-3-top {
    box-shadow: 0 17px 50px rgba(0, 0, 0, 0.19);
}
.z-depth-3-bottom {
    box-shadow: 0 12px 15px rgba(0, 0, 0, 0.24);
}
.z-depth-4 {
    box-shadow:
        0 16px 28px rgba(0, 0, 0, 0.22),
        0 25px 55px rgba(0, 0, 0, 0.21);
}
.z-depth-4-top {
    box-shadow: 0 25px 55px rgba(0, 0, 0, 0.21);
}
.z-depth-4-bottom {
    box-shadow: 0 16px 28px rgba(0, 0, 0, 0.22);
}
.z-depth-5 {
    box-shadow:
        0 27px 24px rgba(0, 0, 0, 0.2),
        0 40px 77px rgba(0, 0, 0, 0.22);
}
.z-depth-5-top {
    box-shadow: 0 40px 77px rgba(0, 0, 0, 0.22);
}
.z-depth-5-bottom {
    box-shadow: 0 27px 24px rgba(0, 0, 0, 0.2);
}
.z-depth-animation .z-depth-1,
.z-depth-animation .z-depth-2,
.z-depth-animation .z-depth-3,
.z-depth-animation .z-depth-4,
.z-depth-animation .z-depth-5 {
    transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

// Effects
.effect-fadein {
    transition: opacity;
    transition-duration: 0.3s;
    opacity: 1;
}
.effect-fadein.end {
    opacity: 1;
}

// Error Message Content
.error-message {
    font-weight: 400;
}
.error-details {
    margin-top: 25px;
    pre {
        padding: 5px;
        border: 1px solid $ae-gray-3;
        background-color: $ae-gray-2;
        border-radius: 3px;
    }
}

// Error pages such as 404, 403
// Eventually this should go in src/app/shared/components/error/error.component.scss
// once that component handles all errors
.error-page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    margin-left: auto;
    margin-right: auto;
    // animation stuff
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeIn;
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    // default values
    > .center-both-directions-flex > div {
        width: 90%;
    }
    img {
        height: 7.5rem;
        width: 7.5rem;
    }
    .display-1 {
        font-weight: $font-weight-bold;
        margin-top: 0.625rem;
        margin-bottom: 0.625rem;
    }
    .display-2 {
        font-size: 2.5rem;
        font-weight: $font-weight-bold;
    }
    a,
    a:visited {
        color: rgba(33, 61, 90, 0.8);
    }
    a:hover {
        color: rgba(33, 61, 90, 1);
    }
    pre.log-text {
        position: absolute;
        left: 0.625rem;
        right: 0.625rem;
        width: auto;
        border: 0;
        padding: 0.625rem;
        margin-bottom: 3.125rem;
        text-align: left;
        background-color: #000000;
        // force wrap, so no scrolling
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    // md or up values
    @include media-breakpoint-up(md) {
        > .center-both-directions-flex > div {
            width: 50%;
        }
        pre.log-text {
            left: 3.125rem;
            right: 3.125rem;
            margin-bottom: 3.125rem;
        }
    }
}

// Toggle button (Container a href for Font Awesome toggle icon + text)
a.btn-toggle {
    i.fa {
        vertical-align: middle;
    }
}

////////////////////////////////////////////////////////////////////////////////
// Tooltips
// ////////////////////////////////////////////////////////////////////////////////

.tooltip > div {
    animation-name: delayedFadeIn;
    animation-duration: 1s; /* Adjust this duration */
}
.nodelay.tooltip > div {
    animation-name: delayedFadeIn;
    animation-duration: 0s;
}
@keyframes delayedFadeIn {
    0% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    } /* Set this to 99% for no fade-in. */
    100% {
        opacity: 1;
    }
}

.delayed-2.tooltip > div {
    animation-name: delayedFadeIn;
    animation-duration: 2s; /* Adjust this duration */
}
.delayed-3.tooltip > div {
    animation-name: delayedFadeIn;
    animation-duration: 3s; /* Adjust this duration */
}

// Tooltips in notifications dispay the notification.message value which could have
// anchor tags which get styled where they are unreadable. This fixes that.
.tooltip-inner {
    a {
        color: $tooltip-color;
    }
}

////////////////////////////////////////////////////////////////////////////////
// Nav-primary
// ////////////////////////////////////////////////////////////////////////////////

#nav-primary {
    left: 0;
    padding: 0 0.625rem 0 0;
    background-color: $white;
    border-bottom: 1px solid grey;
    transition: all;
    transition-duration: $nav-speed;

    .navbar-brand {
        height: $ae-nav-primary-height;
        padding: 0.484375rem 0.546875rem;
        margin-right: 0;

        &.public {
            img {
                height: $ae-nav-primary-height-public;
            }
        }
    }

    // This ensures the help and menu icons are in a row vs column
    .navbar-nav {
        flex-direction: row;
        .search {
            padding: 0.3125rem;
            @include media-breakpoint-down(md) {
                padding: 0.3125rem 0rem;
            }
        }
        .nav-link {
            height: $ae-nav-primary-height;
            padding: 0.78125rem 0.53125rem;

            span:not(.badge) {
                display: none;
            }
        }
        .badge-notification {
            top: 0.625rem;
            right: 0.3125rem;
        }
    }
}

// Admin route
body.is-admin {
    #nav-primary {
        background-color: $black;
    }
}

// Main
#main {
    position: relative;
    height: 100vh;
    min-height: 100vh;
    padding-left: $ae-nav-full-size;
    transition: all 0.3s ease;
    padding-left: 0;
}
// If the nav has different widths, use this...
// body.nav-is-open {
//     #main {
//         @include media-breakpoint-between(md, xl) {
//             padding-left: $ae-nav-full-size;
//         }
//         @include media-breakpoint-down(md) {
//             padding-left: 0;
//         }
//     }
// }

////////////////////////////////////////////////////////////////////////////////
// Loading spinner
////////////////////////////////////////////////////////////////////////////////

body.is-loading {
    overflow-y: hidden;
    //filter: blur(0.0625rem);
    //transition: 200ms all linear;
}
#loading,
#iframe-loading {
    position: absolute;
    z-index: $zindex-tooltip;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.7);
    transition: background-color 1000ms linear;

    .loading-wrapper {
        position: absolute;
        width: 100%;
        top: calc(
            50% - (#{$ae-spinner-height} / 2)
        ); // Must interpolate vars inside calc() with #{}
    }
    .loading-spinner,
    .loading-spinner:after {
        margin-left: auto;
        margin-right: auto;
        border-radius: 50%;
        width: $ae-spinner-height;
        height: $ae-spinner-height;
    }
    .loading-spinner {
        border-top: 0.3125rem solid rgba(0, 0, 0, 0.1);
        border-right: 0.3125rem solid rgba(0, 0, 0, 0.1);
        border-bottom: 0.3125rem solid rgba(0, 0, 0, 0.1);
        border-left: 0.3125rem solid $ae-green;
        transform: translateZ(0);
        animation: load8 1.1s infinite linear;
    }
    .loading-message {
        text-align: center;
        margin-top: 0.625rem;
        font-weight: $font-weight-normal;
        text-shadow: 0 0 0.25rem $white;
        a {
            text-decoration: underline;
        }
    }
}
@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// PUT THIS STUFF IN NAV-PRIMARY
.logo {
    img {
        height: calc(#{$ae-page-header-size} * 0.6);
        margin: 0.4375rem 0.3125rem;
    }
    // auto switch the logo based on the navbar-light/dark
    .navbar-light & {
        .logo-for-dark {
            display: none;
        }
        .logo-for-light {
            display: block;
        }
    }
    .navbar-dark & {
        .logo-for-light {
            display: block;
        }
        .logo-for-dark {
            display: none;
        }
    }
}
// [MOBILE]
@include media-breakpoint-down(sm) {
    .logo {
        img {
            height: calc(#{$ae-page-header-size} * 0.2);
        }
    }
}

.leftnav-trigger {
    position: relative;
    min-height: calc(#{$ae-page-header-size} - 1px);
    width: 100%;
    width: $ae-nav-collapsed-size;
    background-color: transparent;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    transition: transform $nav-speed;

    &:before {
        /* circle background behind icon/trigger */
        content: '';
        position: absolute;
        top: 0.25rem;
        left: 0.25rem;
        width: 1.875rem;
        height: 1.875rem;
        border-radius: 50%;
        background-color: rgba($ae-gray-2, 0.45);
        transition: all;
        transition-duration: #{$nav-speed - 0.2s};
        transform: scale(0);
        backface-visibility: hidden;
        z-index: 0;
        .navbar-dark & {
            background-color: rgba($ae-gray-2, 0.1);
        }
    }

    .leftnav-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        bottom: auto;
        right: auto;
        transform: translateX(-50%) translateY(-50%);
        width: 0.859375rem;
        height: 2px;
        background-color: $black;

        &:before,
        &:after {
            /* upper and lower lines of the menu icon */
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: inherit;
            /* Force Hardware Acceleration in WebKit */
            transform: translateZ(0);
            backface-visibility: hidden;
            transition:
                transform $nav-speed,
                width $nav-speed,
                top #{$nav-speed - 0.2s};
        }
        &:before {
            transform-origin: right top;
            transform: translateY(-6px);
        }
        &:after {
            transform-origin: right bottom;
            transform: translateY(6px);
        }
        .navbar-dark & {
            background-color: $white;
        }
    }

    // If the screen width is Bootstrap `lg` or higher, hide the leftnav-trigger
    // @include media-breakpoint-up(lg) {
    //     display: none;
    // }
}

body.nav-is-open {
    .leftnav-trigger {
        /* rotate trigger when navigation becomes visible */
        transform: rotate(180deg);
        &:before {
            transform: scale(1);
        }
        .leftnav-icon {
            &:before,
            &:after {
                /* animate arrow --> from hamburger to arrow */
                width: 50%;
                transition:
                    transform $nav-speed,
                    width $nav-speed;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}

body.nav-is-docked {
    .leftnav-trigger {
        display: none;

        // show the nav trigger if we are in a small screen
        @include media-breakpoint-down(sm) {
            display: block;
        }
    }
}

// ---------------------------------------------------------
// Draggable Directive Styling
// ---------------------------------------------------------
[draggable] {
    &.new-position {
        border-top: 2px solid rgba(68, 68, 68, 1);
        transition: all 0.3s;
    }

    &.draggable-cursor {
        cursor: move;
    }

    &.drag-element {
        opacity: 0.4;
    }
}

// ---------------------------------------------------------
// nav-header (not used)
// ---------------------------------------------------------
#nav-header {
    border-bottom: 1px solid $border-color;
    line-height: 0;
    transition: all $nav-speed ease;
    padding: 0;
    margin: 0;
}

// ---------------------------------------------------------
// nav-nav (not used)
// ---------------------------------------------------------
#nav-nav {
    > li {
        > a {
            transition: all $nav-speed;
            position: relative;
            height: 1.71875rem;
            padding: 0.4375rem;
            margin: 0.3125rem;
            display: block;
            position: relative;
            text-decoration: none;
            white-space: nowrap;
            border-radius: 0.3125rem;
            background-color: rgba(0, 0, 0, 1);
            color: $white;
            &:hover {
                background-color: $ae-green;
            }
            i {
                margin-right: 0.625rem;
            }
            .title {
                display: none;
                // Hold my beer, this is some cool sass trickery
                body.nav-is-open & {
                    display: inline-block;
                }
            }
        }
    }
}

// From Core Shared
/******************************************************************************/
/* GLOBALS
/*     These are styles that will be available on every page to any component.
/***********************************/

// Imports
@import 'variables';
@import 'bootstrap/scss/mixins';

// Defaults
a,
.is-clickable {
    cursor: pointer;
}
.noscroll {
    overflow-y: hidden;
}
.noselect {
    user-select: none;
}
.nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.nowrap-left {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    text-align: left;
}
.nowrap-flex {
    flex: 1;
    &:not([class*='line-clamp-']),
    & > *:not([class*='line-clamp-']) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

:disabled,
.noclick {
    pointer-events: none;
    cursor: not-allowed;
}
.force-word-break {
    display: block;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    hyphens: auto;
}
td.force-word-break {
    display: table-cell;
}

// Taken from: https://css-tricks.com/centering-css-complete-guide/
.center-both-directions-flex {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.center-both-directions-absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    text-align: center;
}

// Right Nav
#nav-primary,
#main {
    /* Force Hardware Acceleration */
    transform: translateZ(0);
    // ONLY transition the transform (this way #main doesn't animate when you simply resize the browser)
    transition: transform;
    transition-duration: $nav-speed;
}
// Right Nav is open
body.rightnav-is-open {
    #main,
    #nav-primary {
        transform: translate3d(-$ae-nav-right-width, 0, 0);
    }
    #nav-right {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

// SUBNAV
.nav.subNav {
    padding-left: 2em;
}

#content {
    position: absolute; // IE11 doesn't like position: fixed + translate3d
    right: 0;

    top: #{$ae-nav-primary-height}; // Must interpolate vars inside calc() with #{};

    bottom: 0;
    overflow: auto;
    background-color: $white;
    transition: all $nav-speed ease;
    //padding: 0.625rem 0.625rem 0.625rem 0.625rem;

    &.public {
        background-color: $ae-gray-2;
        top: $ae-nav-secondary-height;
    }
}

body.nav-is-open.nav-is-docked #content {
    @include media-breakpoint-up(lg) {
        width: calc(100% - #{$ae-nav-full-size});
    }

    @include media-breakpoint-down(md) {
        width: calc(100% - #{$ae-nav-half-size});
    }
}

body.banner {
    #content-new {
        #content-inner.container {
            max-width: none;
            .row {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}

body.banner {
    #content-new {
        padding-top: 0;
    }
}

#content-new {
    position: absolute; // IE11 doesn't like position: fixed + translate3d
    right: 0;
    left: 0;
    top: 4.6875rem;
    padding-top: 2.875rem;
    overflow: auto;
    background-color: $white;
    bottom: 0;
}

#content-child {
    position: absolute; // IE11 doesn't like position: fixed + translate3d
    right: 0;
    top: calc(#{$ae-nav-secondary-height}); // Must interpolate vars inside calc() with #{};
    bottom: 0;
    overflow: auto;
    background-color: $white;
    padding: 0.625rem 0.625rem 0.625rem 0.625rem;

    &.public {
        background-color: $ae-gray-2;
        top: $ae-nav-secondary-height;
    }
}

// Override the #content width for sm and lower
// (see nav-tertiary for corresponding nav styles)
@include media-breakpoint-down(sm) {
    #content.col-11 {
        flex: 0 0 calc(100% - #{$ae-nav-primary-height});
        max-width: calc(100% - #{$ae-nav-primary-height});
    }
}
#content-inner {
    min-height: 100%;
    background-color: $white;
    padding: 0;
}
#content-inner-child {
    min-height: 100%;
    background-color: $white;
    padding: 0.625rem;
    overflow-x: auto;
}

// No Content
// If no content on top level #content
#content.nocontent {
    background-color: $ae-gray-2;
    #content-inner {
        background-color: $ae-gray-2;
    }
}
// If no content is on a child of #content
#content #content-inner .nocontent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $ae-gray-2;
}
.nocontent-message {
    color: $ae-green;
}

// Page
.page {
    position: relative;
    height: 100%;
    padding: calc(#{$ae-nav-primary-height} + 0.625rem) 0.625rem 0rem 0.625rem; // Must interpolate vars inside calc() with #{}
}
.page.with-nav-secondary {
    padding-top: calc(
        #{$ae-nav-primary-height} + #{$ae-nav-secondary-height} + 0.625rem
    ); // Must interpolate vars inside calc() with #{}
}
.page.with-nav-tertiary {
    #content {
        background-color: $ae-gray-2;
    }
}

// iFrame
.iframe-wrapper {
    position: absolute;
    .iframe-content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
.iframe-wrapper-full {
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.iframe-wrapper-inset {
    top: 3.75rem;
    bottom: 0.625rem;
    left: 0.625rem;
    right: 0.625rem;

    &.is-child {
        // stupid ass hack. sorry
        top: 0.625rem;
    }
}

// Line Clamps
.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
}
.line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
}
.line-clamp-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
}
td.line-clamp-2 {
    height: 2.625rem;
}
td.line-clamp-3 {
    height: 3.5625rem;
}
td.line-clamp-4 {
    height: 4.5rem;
}

// Z-Depth (based off Material Design)
.z-depth-half {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}
/* from material shadows https://github.com/mrmlnc/material-shadows */
.z-depth-1 {
    box-shadow:
        0 2px 5px rgba(0, 0, 0, 0.16),
        0 2px 10px rgba(0, 0, 0, 0.12);
}
.z-depth-1-top {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
}
.z-depth-1-bottom {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
}
.z-depth-2 {
    box-shadow:
        0 8px 17px rgba(0, 0, 0, 0.2),
        0 6px 20px rgba(0, 0, 0, 0.19);
}
.z-depth-2-top {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.19);
}
.z-depth-2-bottom {
    box-shadow: 0 8px 17px rgba(0, 0, 0, 0.2);
}
.z-depth-3 {
    box-shadow:
        0 12px 15px rgba(0, 0, 0, 0.24),
        0 17px 50px rgba(0, 0, 0, 0.19);
}
.z-depth-3-top {
    box-shadow: 0 17px 50px rgba(0, 0, 0, 0.19);
}
.z-depth-3-bottom {
    box-shadow: 0 12px 15px rgba(0, 0, 0, 0.24);
}
.z-depth-4 {
    box-shadow:
        0 16px 28px rgba(0, 0, 0, 0.22),
        0 25px 55px rgba(0, 0, 0, 0.21);
}
.z-depth-4-top {
    box-shadow: 0 25px 55px rgba(0, 0, 0, 0.21);
}
.z-depth-4-bottom {
    box-shadow: 0 16px 28px rgba(0, 0, 0, 0.22);
}
.z-depth-5 {
    box-shadow:
        0 27px 24px rgba(0, 0, 0, 0.2),
        0 40px 77px rgba(0, 0, 0, 0.22);
}
.z-depth-5-top {
    box-shadow: 0 40px 77px rgba(0, 0, 0, 0.22);
}
.z-depth-5-bottom {
    box-shadow: 0 27px 24px rgba(0, 0, 0, 0.2);
}
.z-depth-animation .z-depth-1,
.z-depth-animation .z-depth-2,
.z-depth-animation .z-depth-3,
.z-depth-animation .z-depth-4,
.z-depth-animation .z-depth-5 {
    transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

// Effects
.effect-fadein {
    transition: opacity;
    transition-duration: 0.3s;
    opacity: 1;
}
.effect-fadein.end {
    opacity: 1;
}

// Error Message Content
.error-message {
    font-weight: 400;
}
.error-details {
    margin-top: 25px;
    pre {
        padding: 5px;
        border: 1px solid $ae-gray-3;
        background-color: $ae-gray-2;
        border-radius: 3px;
    }
}

// Error pages such as 404, 403
// Eventually this should go in src/app/shared/components/error/error.component.scss
// once that component handles all errors
.error-page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    margin-left: auto;
    margin-right: auto;
    // animation stuff
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeIn;
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    // default values
    > .center-both-directions-flex > div {
        width: 90%;
    }
    img {
        height: 7.5rem;
        width: 7.5rem;
    }
    .display-1 {
        font-weight: $font-weight-bold;
        margin-top: 0.625rem;
        margin-bottom: 0.625rem;
    }
    .display-2 {
        font-size: 2.5rem;
        font-weight: $font-weight-bold;
    }
    a,
    a:visited {
        color: $ae-accent-3;
    }
    a:hover {
        color: $ae-accent-3;
    }
    pre.log-text {
        position: absolute;
        left: 0.625rem;
        right: 0.625rem;
        width: auto;
        border: 0;
        padding: 0.625rem;
        margin-bottom: 3.125rem;
        text-align: left;
        background-color: #000000;
        // force wrap, so no scrolling
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    // md or up values
    @include media-breakpoint-up(md) {
        > .center-both-directions-flex > div {
            width: 50%;
        }
        pre.log-text {
            left: 3.125rem;
            right: 3.125rem;
            margin-bottom: 3.125rem;
        }
    }
}

// Toggle button (Container a href for Font Awesome toggle icon + text)
a.btn-toggle {
    i.fa {
        vertical-align: middle;
    }
}

////////////////////////////////////////////////////////////////////////////////
// Tooltips
// ////////////////////////////////////////////////////////////////////////////////

bs-tooltip-container.tooltip {
    font-size: 0.875rem;
}

.tooltip > div {
    animation-name: delayedFadeIn;
    animation-duration: 1s; /* Adjust this duration */
}
.nodelay.tooltip > div {
    animation-name: delayedFadeIn;
    animation-duration: 0s;
}
@keyframes delayedFadeIn {
    0% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    } /* Set this to 99% for no fade-in. */
    100% {
        opacity: 1;
    }
}

.delayed-2.tooltip > div {
    animation-name: delayedFadeIn;
    animation-duration: 2s; /* Adjust this duration */
}
.delayed-3.tooltip > div {
    animation-name: delayedFadeIn;
    animation-duration: 3s; /* Adjust this duration */
}

// Tooltips in notifications dispay the notification.message value which could have
// anchor tags which get styled where they are unreadable. This fixes that.
.tooltip-inner {
    a {
        font-size: 1rem;
        color: $tooltip-color;
    }
}

////////////////////////////////////////////////////////////////////////////////
// Nav-primary
// ////////////////////////////////////////////////////////////////////////////////

// Updating this here so its specific to this theme
#nav-primary {
    left: 0;
    padding: 0 0.625rem 0 0;
    background-color: $ae-primary-7;
    transition: all;
    transition-duration: $nav-speed;
    display: block;

    .navbar-brand {
        height: $ae-nav-primary-height;
        padding: 0.484375rem 0.546875rem;
        margin-right: 0;

        &.public {
            img {
                height: $ae-nav-primary-height-public;
            }
        }
    }

    // This ensures the help and menu icons are in a row vs column
    .navbar-nav {
        flex-direction: row;
        .search {
            padding: 0.3125rem;
            @include media-breakpoint-down(md) {
                padding: 0.3125rem 0rem;
            }
        }
        .nav-link {
            text-decoration: none;
            height: $ae-nav-primary-height;
            padding: 0.78125rem 0.53125rem;

            span:not(.badge) {
                display: none;
            }
        }
        .badge-notification {
            top: 0.625rem;
            right: 0.3125rem;
        }
        .dropdown-menu {
            background-color: $ae-primary-7;
            margin-right: 5.875rem;
            min-width: 14rem;
            box-shadow: 0px 3px 6px #00000029;
            li,
            a {
                color: $white;
                text-decoration: none;
                &:hover {
                    // These shouldn't change color when hovered
                    color: $white !important;
                }
            }
            .dropdown-item {
                height: 3rem;
                padding-top: 0.78125rem;
                padding-left: 1.5rem;
                &:hover {
                    background-color: $ae-accent-4;
                }
            }
            .dropdown-divider {
                margin: 0 1.5rem;
            }
        }
    }
}

// Admin route
body.is-admin {
    #nav-primary {
        background-color: $black;
    }
}

// Main
#main {
    position: relative;
    height: 100vh;
    min-height: 100vh;
    padding-left: $ae-nav-full-size;
    transition: all 0.3s ease;
    padding-left: 0;
}
// If the nav has different widths, use this...
// body.nav-is-open {
//     #main {
//         @include media-breakpoint-between(md, xl) {
//             padding-left: $ae-nav-full-size;
//         }
//         @include media-breakpoint-down(md) {
//             padding-left: 0;
//         }
//     }
// }

////////////////////////////////////////////////////////////////////////////////
// Loading spinner
////////////////////////////////////////////////////////////////////////////////

body.is-loading {
    overflow-y: hidden;
    //filter: blur(0.0625rem);
    //transition: 200ms all linear;
}
#loading,
#iframe-loading {
    position: absolute;
    z-index: $zindex-tooltip;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.7);
    transition: background-color 1000ms linear;

    .loading-wrapper {
        position: absolute;
        width: 100%;
        top: calc(
            50% - (#{$ae-spinner-height} / 2)
        ); // Must interpolate vars inside calc() with #{}
    }
    .loading-spinner,
    .loading-spinner:after {
        margin-left: auto;
        margin-right: auto;
        border-radius: 50%;
        width: $ae-spinner-height;
        height: $ae-spinner-height;
    }
    .loading-spinner {
        border-top: 0.3125rem solid rgba(0, 0, 0, 0.1);
        border-right: 0.3125rem solid rgba(0, 0, 0, 0.1);
        border-bottom: 0.3125rem solid rgba(0, 0, 0, 0.1);
        border-left: 0.3125rem solid $ae-green;
        transform: translateZ(0);
        animation: load8 1.1s infinite linear;
    }
    .loading-message {
        text-align: center;
        margin-top: 0.625rem;
        font-weight: $font-weight-normal;
        text-shadow: 0 0 0.25rem $white;
        a {
            text-decoration: underline;
        }
    }
}
@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// Star Rating (not currently used)
.star-rating {
    color: $ae-secondary-5;
    .star-text {
        color: $ae-secondary-6;
        font-size: 0.46875rem;
        margin-left: 0.3125rem;
    }
}

// Avatar
.avatar {
    align-content: center;
    align-items: center;
    background: $white;
    border: 5px solid $ae-secondary-9;
    height: 121px;
    width: 121px;

    &.avatar-small {
        border: 3px solid $ae-secondary-9;
        height: 76px;
        width: 76px;
    }

    img {
        height: 100%;
        width: 100%;
    }
}

.payment-confirmation {
    #content-new {
        background: transparent linear-gradient(180deg, #e1f1f180 0%, #ffffff 100%) 0 0px no-repeat
            padding-box;
        padding-top: 0;

        #content-inner {
            background: none;
            max-width: 100%;
        }
    }
}

.registration-sub-headline {
    color: $ae-secondary-7;
}

.registration-helper-links {
    .btn,
    &.font-weight-bolder {
        font-size: 1.25rem;
    }

    a,
    span {
        font-size: 0.75rem;
    }

    a {
        color: $ae-secondary-7;
        text-decoration: underline;
    }
}

///////////////////////////////////////////////
//          Global Font Styles               //
///////////////////////////////////////////////

html {
    font-size: 100%;
}

h1,
.h1 {
    font-family: 'Museo Sans Rounded';
    font-size: 3rem;
    line-height: 3.625rem;
    font-weight: 300;
    color: $black;
}

h2,
.h2 {
    font-family: 'Museo Sans Rounded';
    font-size: 2.25rem;
    line-height: 2.875rem;
    font-weight: 300;
    color: $ae-gray-3;
}

h3,
.h3 {
    font-family: 'Museo Sans Rounded';
    font-size: 1.875rem;
    line-height: 2.5rem;
    font-weight: 700;
    color: $ae-gray-4;
}

h4,
.h4 {
    font-family: 'Museo Sans Rounded';
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 300;
    color: $ae-gray-4;
}

h5,
.h5 {
    font-family: 'Museo Sans Rounded';
    font-size: 1.5rem;
    line-height: 2rem;
    text-transform: uppercase;
    color: $ae-gray-4;
    font-weight: 700;
}

h6,
.h6 {
    font-family: 'Museo Sans Rounded';
    font-size: 1.5rem;
    line-height: 2rem;
    color: $black;
    font-weight: 700;
}

p {
    font-family: 'Museo Sans Rounded';
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300;
    color: $ae-gray-5;
}

// Overwrites for articles and content descriptions
bb-froala-editor p {
    font-size: 1.25rem;
    line-height: 2rem;
}

.fr-img-caption {
    color: $ae-gray-6;
    text-align: left;
    font-size: 0.875rem;
}

pre {
    background-color: #f4f4f4;
    border: 0 none;
    font-size: 1.125rem;
    line-height: 1.875rem;
}

blockquote,
blockquote.fr-view {
    border-left: solid 2px $ae-secondary-2;
    padding-left: 0.3125rem;
}

a {
    font-size: 0.75rem;
}

li {
    font-size: 1rem;
}

label {
    font-size: 0.875rem;
    color: $ae-gray-3;
}

i {
    font-weight: 300;
    font-size: 1rem;
}

span,
a {
    font-size: inherit;
}

a {
    text-decoration: underline;

    &:hover {
        color: $ae-accent-5;
    }
}

small {
    font-size: 0.75rem;
}

strong {
    font-weight: 700;
}

.text-green,
.textGreen {
    color: #43b049;
}

.hover-green-underline {
    text-decoration: none;
    &:hover {
        color: #43b049;
        text-decoration: underline;
    }
}

.text-blue,
.textBlue {
    color: #00a5b5;
}

.text-gray {
    color: $ae-gray-3;
}

.text-black {
    color: black;
}

.hover-green {
    &:hover {
        color: $ae-secondary-7;
    }
}

.text-white {
    color: $white;
}

.text-blue {
    color: $ae-accent-4;
}

.text-light-gray {
    color: $ae-gray-3;
}

.text-small {
    font-size: 0.875rem;
}

.text-underline {
    text-decoration: underline;
}

.text-danger {
    font-size: 0.875rem;
    line-height: 1.5625rem;
    &::before {
        content: '\f06a';
        color: $ae-primary-4;
        font-family: 'Font Awesome 5 Pro';
        font-size: 0.875rem;
        font-weight: 500;
        color: $danger;
        padding-right: 0.625rem;
    }
}

.btn-primary {
    &.btn-green {
        background: $ae-primary-1;
        border-color: $ae-primary-1;
    }
}

////////////////////////////////////////////////////
//              Forms                             //
////////////////////////////////////////////////////

.form-group,
.form-row {
    margin-bottom: 0.9375rem;
    label {
        color: $ae-gray-3;
    }
    input,
    textarea {
        border-radius: 4px;
        border: 1px solid $ae-gray-3;
        color: $ae-gray-3;

        &.form-control-plaintext {
            border: none;
            font-size: 1rem;
        }
    }
    *::placeholder {
        color: $ae-gray-1;
    }

    .col-form-label {
        font-size: 1rem;
    }
}

.form-check {
    label {
        color: $ae-gray-3;
        font-size: 1rem;
    }
}

// This styles the input group to be invisible
// it allows us to add the eye-cons to toggle
// the password fields to show/hide the passwords
.password-group {
    input {
        border: 1px solid #707070;
        border-right: 0 none;
    }
    .input-group-text {
        background-color: white;
        border: 1px solid #707070;
        border-left: 0 none;
        span {
            font-size: 1rem;
        }
    }
}

////////////////////////////////////////////////////
//              Toast                             //
////////////////////////////////////////////////////

#toast-container {
    font-size: 1rem;
}

////////////////////////////////////////////////////
//              Alert                             //
////////////////////////////////////////////////////

.alert {
    font-size: 1rem;
}

////////////////////////////////////////////////////
//              Content                           //
////////////////////////////////////////////////////
.document-header {
    background-color: $ae-background-4;

    &.blueprint {
        .container,
        .container-fluid {
            padding-bottom: 1.25rem;
            padding-top: 3.125rem;
        }

        .container-fluid {
            margin-left: 5rem;
            margin-right: 5rem;

            // [MOBILE]
            @include media-breakpoint-down(sm) {
                padding-left: 0.625rem;
                padding-right: 0.625rem;
                margin-left: 0.625rem;
                margin-right: 0.625rem;
            }
        }
    }

    .content-type {
        font-size: 1.25rem;
        line-height: 2rem;
    }
}

.early-access {
    background: $ae-background-5;
    padding: 1.5625rem;

    p {
        font-weight: bold;
        font-size: 1.25rem;
        margin-bottom: 0;
    }
}

.col-8 {
    .summary {
        .font-weight-bold {
            font-size: 1.5rem;
        }
    }
}

// Markdown Overrides
markdown,
.static-page-markdown {
    a,
    p,
    li,
    th,
    td {
        font-size: 1.25rem;
        line-height: 2rem;
    }

    // Image Caption - We need a better way to apply these
    // saving the styles for now
    // p em {
    //     font-style: normal;
    //     padding: 1.1875rem 1.25rem;
    //     background-color: #E1F1F1;
    //     border-top: solid #00A5B5 0.625rem;
    //     width: 100%;
    //     display: block;
    //     font-size: 1.125rem;
    // }

    p {
        padding: 0.625rem 0;
    }

    a {
        text-decoration: underline;
        color: $ae-secondary-7;
    }

    img {
        width: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        padding: 1.25rem 0;
    }

    pre {
        background-color: #f4f4f4;
        border: 0 none;
        code {
            font-size: 1.125rem;
            line-height: 1.875rem;
        }
    }
}

// ---------------------------------------------------------
// LazyImage Directive Styling
// ---------------------------------------------------------
[libLazyImage] {
    opacity: 0;
}

[libLazyImage].lazy-image-loaded {
    opacity: 1;
    transition: opacity 1.5s;
}

// ---------------------------------------------------------
// Support Center Styling
// ---------------------------------------------------------
.support {
    h1 {
        margin-bottom: 1.25rem;
    }

    h3 {
        span {
            color: $ae-gray-6;
            font-size: 1.5rem;
            font-weight: 100;
            margin-left: 0.625rem;
        }
    }

    .sub-section-header {
        font-size: 1.25rem;
    }

    .tagline {
        font-size: 1.25rem;
        color: #50504f;
    }

    .card {
        a {
            text-decoration: none;
        }

        i {
            color: $ae-primary-1;
        }

        hr {
            border: 1px solid $ae-primary-1;
        }

        .title,
        .title .d-flex {
            min-height: 3.625rem;
        }

        .title {
            h6 {
                width: 100%;
            }
        }

        .content {
            font-size: 1.125rem;
            padding: 0 2.5rem;
        }
    }
}

.support-links {
    h3 {
        font-weight: 100;
        margin-bottom: 0.625rem;
    }

    li,
    li > li {
        font-size: 1.25rem;
        margin-bottom: 0.5rem;

        a {
            color: $ae-primary-1;
        }
    }

    li > ul {
        margin-top: 0.625rem;
    }
}

// ---------------------------------------------------------
// Drag n Drop
// ---------------------------------------------------------
.general_dnd_container {
    position: relative;
    width: 100%;
    height: 225px;
    border-style: dashed;
    border-width: 4px;
    border-radius: 10px;
    border-color: $ae-accent-4;
    background-color: $ae-background-4;

    #fileDropRef {
        opacity: 0;
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    h5 {
        color: $ae-accent-4;
        text-align: center;
        text-transform: uppercase;
    }
}
