// KENDO NOTIFICATIONS
.k-notification.bb-notification {
    display: flex;
    padding: 20px;
    font-size: 1rem;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin: 10px;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        box-shadow: 0 0 5px #999;
    }
}

// KENDO DIALOG
.k-window.k-dialog {
    max-height: 95%;
}
