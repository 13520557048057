/*
* Observed Heights:
* These are hardcoded observed heights for different elements in big-bend.
* They are currently used for the positioning of global elements in relation to each other.
*/
$lib-header-height: 4.6875rem;
$secondary-nav-height: 4rem;

/******************************************************************************/
/* VARIABLES
/*     These are variables from BOTH Anaconda and ones we wish to
/*     customize from Bootstrap. The Anaconda ones are prefixed "$ae-".
/*     We must include this file before Bootstrap according to:
/*     https://getbootstrap.com/docs/4.0/getting-started/theming/
/***********************************/

/////////////////////////////////////////////////
//          2020 Marketing Colors              //
/////////////////////////////////////////////////

// Primary
$ae-primary-1: rgba(67, 176, 73, 1); // #43B049
$ae-primary-2: rgba(128, 188, 0, 1); // #80BC00
$ae-primary-3: rgba(0, 161, 155, 1); // #00A19B
$ae-primary-4: rgba(0, 117, 169, 1); // #005E87
$ae-primary-5: rgba(6, 38, 45, 1); // #06262D
$ae-primary-6: rgba(255, 255, 255, 1); // #FFFFFF
$ae-primary-7: rgba(16, 36, 67, 1); // #102443
$ae-primary-8: rgba(13, 33, 37, 1); // #0D2125

// Secondary
$ae-secondary-1: rgba(14, 23, 43, 1); // #0E172B
$ae-secondary-2: rgba(0, 165, 181, 1); // #00A5B5
$ae-secondary-3: rgba(220, 240, 239, 1); // #DCF0EF
$ae-secondary-4: rgba(26, 86, 50, 1); // #1A5632
$ae-secondary-5: rgba(246, 233, 72, 1); // #F6E948
$ae-secondary-6: rgba(85, 101, 117, 1); // #556575
$ae-secondary-7: rgba(91, 166, 65, 1); // #5BA641
$ae-secondary-8: rgba(26, 130, 65, 1); // #1A8241
$ae-secondary-9: rgba(67, 176, 63, 1); // #43B03F
$ae-secondary-10: rgba(110, 121, 122, 1); // #6E797A

// Accent Colors
$ae-accent-1: rgba(245, 168, 0, 1); // #F5A800
$ae-accent-2: rgba(219, 96, 21, 1); // #DB6015
$ae-accent-3: rgba(0, 165, 181, 1); // #00A5B5
$ae-accent-4: rgba(0, 117, 169, 1); // #0075A9
$ae-accent-5: rgba(0, 165, 181, 1); // #00A5B5
$ae-accent-6: rgba(219, 96, 21, 1); // #DB6015
$ae-accent-7: rgba(245, 168, 0, 0.25); // #F5A80040
$ae-accent-8: rgba(194, 213, 0, 1); // #C2D500
$ae-accent-9: rgba(18, 40, 76, 1); // #12284C

// Backgrounds
$ae-background-1: rgba(0, 164, 153, 0.1); // PANTONE 3272C
$ae-background-2: rgba(8, 35, 39, 1); // #082327
$ae-background-3: rgba(225, 241, 241, 1); // #E1F1F1
$ae-background-4: rgba(243, 247, 247, 1); // ##F3F7F7
$ae-background-5: rgba(232, 241, 233, 1); // #E8F1E9

// Grays
$ae-gray-1: rgba(217, 216, 214, 1); //#D9D8D6
$ae-gray-2: rgba(158, 172, 170, 1); //#9EACAA
$ae-gray-3: rgba(112, 112, 112, 1); //#707070
$ae-gray-4: rgba(55, 58, 54, 1); //#373A36
$ae-gray-5: rgba(76, 76, 76, 1); //#4C4C4C
$ae-gray-6: rgba(110, 124, 124, 1); //#6E7C7C

// Assign to values
$ae-green: $ae-primary-1;
$primary: $ae-primary-1;
$secondary: $ae-secondary-3;
$danger: rgba(186, 12, 47, 1);
$warning: $ae-accent-2;
$success: $ae-primary-1;
$white: #fff;
$black: $ae-primary-5;
$dark: $ae-primary-5;
$light: $ae-gray-1;

// Fonts
$fa-font-path: '../../../node_modules/font-awesome/fonts';

// BS 4 does a pretty cool native font stack (not sure if sans-serif triggers this): https://getbootstrap.com/docs/4.0/content/reboot/#native-font-stack
$font-family-base: 'Museo Sans Rounded', serif;
$font-weight-light: 200;
$font-weight-normal: 300;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;
$text-muted: $ae-gray-3;

// Misc
$border-radius: 3px;
$page-padding-top: 2.875rem;

// Links
$link-color: $ae-primary-5;

// Buttons and Forms
$input-padding-y: 0.5rem;
$input-padding-x: 0.625rem;
// The input's font weight is set in bootstrap-overrides.scss (see .form-control)
// The input-height and input-height-inner is taken from Bootstrap 4.3.1, until we can fully upgrade to it,
// b/c 4.2.1 exhibits the bug AENT-4938
$input-height: 2.25rem;
$input-height-inner: 2.5rem;

// Navs
$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 0.625rem;

$navbar-dark-color: rgba($white, 0.9);
$navbar-dark-hover-color: rgba($white, 1);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, 0.25);
$navbar-dark-toggler-border-color: tranparent;

$navbar-light-color: rgba($black, 1);
$navbar-light-hover-color: rgba($black, 0.5);
$navbar-light-active-color: rgba($black, 1);
$navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-toggler-border-color: transparent;

$navbar-toggler-border-radius: 1rem;

$nav-speed: 0.3s;

$ae-nav-collapsed-size: 2.34375rem;
$ae-nav-full-size: 8.125rem;
$ae-nav-half-size: 2.0625rem;
$ae-page-header-size: 6.25rem;

// Old sizes...
$ae-nav-primary-height: 2.34375rem;
$ae-nav-primary-height-public: 1.46875rem;
$ae-nav-primary-height-expanded: 18.75rem;
$ae-nav-secondary-height: 2.34375rem;
$ae-nav-right-width: 12.5rem;

$ae-nav-link-text-padding: 0.6875rem;
$ae-nav-link-icon-padding: $nav-link-padding-x;

// Cards
$card-cap-bg: $ae-gray-1;

// Modals
$modal-inner-padding: 0.78125rem;
$modal-content-bg: $ae-gray-1;
$modal-content-border-color: $ae-gray-3;

// Tables
$table-head-color: $ae-gray-1;

// Breadcrumbs
$breadcrumb-padding-y: 0.3125rem;

// Grid breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px,
);

// Grid containers
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1080px,
    xxl: 1320px,
);

// Loading spinner
$ae-spinner-height: 3.125rem;

$artifact-list-item-hover-bg-color: #f8f9fa;

$spacer: 0.625rem !default;
$spacers: () !default;
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.5,
        ),
        5: (
            $spacer * 3,
        ),
        6: (
            $spacer * 6,
        ),
    ),
    $spacers
);
